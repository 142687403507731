import validate from 'validate.js';

export default (entityData, validationState, isRegistration = false) => {
    let valid = true;
    let attrs = entityData.attributes ? entityData.attributes : entityData
    const requiredAttrs = [
      {key: 'field_contact_name', type: 'text'},
      {key: 'field_organization_name', type: 'text'},
      {key: 'field_phone', type: 'phone'},
      {key: 'field_website', type: 'url', optional: true},
      {key: 'field_provider_or_express_id', type: 'text'}
    ];
    if(isRegistration){
      requiredAttrs.push({key: 'mail', type: 'email'});
      requiredAttrs.push({key: 'name', type: 'text'},);
    }
    const requiredAttrKeys = requiredAttrs.map(({key}) => key);
    function hasAttr(field){
      let field_conf = requiredAttrs.find((a_conf) => a_conf.key === field);
      if((attrs && attrs[field])){
        let attr_val = typeof attrs[field].value !== 'undefined' ? attrs[field].value : attrs[field];
        if(attr_val){
          switch (field_conf.type) {
            case 'url':
              let url_val = attr_val.uri ? attr_val.uri : attr_val;
              return !validate({website: url_val}, {website: {url: true}});
            case 'email':
              return !validate({email: attr_val}, {email: {email: true}});
            case 'phone':
              var pattern = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g;
              return !validate({phone: attr_val}, {phone: {format: pattern}});
            default:
              return (attrs && attr_val);
          }
        }else{
          return false
        }
      }else{
        return false
      }
    }
    for(let v of Object.keys(validationState)){
      let field_conf = requiredAttrs.find((a_conf) => a_conf.key === v);
      const [validation, setValidation] = validationState[v];
      if(requiredAttrKeys.includes(v)){
        const has_attr = hasAttr(v);
        const attr_val = typeof attrs[v].value !== 'undefined' ? attrs[v].value : attrs[v];
        const is_valid_field = has_attr || (!has_attr && field_conf.optional && (!attr_val || attr_val.length === 0))
        if(!is_valid_field){
          valid = false;
          let validation_error_msg = 'Required field';
          if(v === 'field_phone'){
            validation_error_msg = 'Please use the required format: 1 555-555-5555';
          }else if(v === 'field_website'){
            validation_error_msg = 'Please add a valid URL including http:// at the start';
          }
          setValidation(validation_error_msg);
        }else if(validation){
          setValidation(false);
        }
      }
    }
    return valid;
  }