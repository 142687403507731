import validate from 'validate.js';
import { removeTags } from '.';

export default (entityData, validationState, data, geolocation) => {
    let valid = true;
  
    const requiredAttrs = [
      {key: 'title', type: 'text'},
      {key: 'field_company_name', type: 'text'},
      {key: 'field_salary_number', type: 'number'},
      {key: 'field_salary_number_max', type: 'number'},
      {key: 'field_application_url', type: 'url'},
      {key: 'field_required_documents', type: 'text', multiple: true}
    ];
    const requiredAttrKeys = requiredAttrs.map(({key}) => key)
    
    const requiredRelationships = ['field_education', 'field_position', 'field_application_method', 'field_expiration', 'field_category'];
    
    const allTextAreas = [
      {key: 'field_description', max: 2000, required: true},
      {key: 'field_required_qualifications', max: 2000, required: true},
      {key: 'field_preferred_qualifications', max: 2000, required: false},
      {key: 'field_benefits', max: 2000, required: false},
    ];
    const allTextAreasKeys = allTextAreas.map(({key}) => key)
  
    function hasAttr(entityData, field){
      let field_conf = requiredAttrs.find((a_conf) => a_conf.key === field);
      switch (field_conf.type) {
        case 'url':
          return entityData.attributes && entityData.attributes[field] && !validate({website: entityData.attributes[field]}, {website: {url: true}});
        default:
          return (entityData.attributes && entityData.attributes[field]);
      }
    }
    function hasValidTextareaValue(entityData, field){
      let field_conf = allTextAreas.find((a_conf) => a_conf.key === field);
      let has_attr = (entityData.attributes && entityData.attributes[field] && entityData.attributes[field].value);
      let description_plain = has_attr ? removeTags(entityData.attributes[field].value) : '';
      let valid_text = description_plain.length <= field_conf.max && description_plain.length > 0;
      if(!valid_text){
        return !(field_conf.required || (!field_conf.required && description_plain.length > 0))
      }
      return (field_conf.required && valid_text) || (!field_conf.required && description_plain.length > 0)
    }

    function validRel(rel_obj){
      return rel_obj.id && typeof rel_obj.id === 'string' && rel_obj.id.length > 0
    }
    
    function hasRel(entityData, field){
      if(entityData.relationships && entityData.relationships[field] && entityData.relationships[field].data){
        if(Array.isArray(entityData.relationships[field].data)){
          const valid_rels = entityData.relationships[field].data.filter(a_rel => validRel(a_rel))
          return valid_rels.length === entityData.relationships[field].data.length
        }else{
          return validRel(entityData.relationships[field].data)
        }
      }else{
        return false
      }
    
    }
    function hasAddress(entityData){
      return (entityData.attributes && entityData.attributes.field_address &&
        entityData.attributes.field_address.locality && entityData.attributes.field_address.address_line1 &&
        entityData.attributes.field_address.postal_code);
    }
  
    function relationshipLike(like, rel_data, terms) {
      if(terms && terms.nodes){
        let id = rel_data.id ? rel_data.id : rel_data;
        let match = terms.nodes.find((term) => {
          return id === term.drupal_id;
        })
        if(match){
          if(match.name.toLowerCase().includes(like.toLowerCase())){
            return true;
          }
        }
      }
      return false;
    }
  
    for(let v of Object.keys(validationState)){
      const [validation, setValidation] = validationState[v];
      const is_salary_range = v === 'field_salary_number_max' || v === 'field_salary_number';
      if(requiredAttrKeys.includes(v) && !is_salary_range){
        if(!hasAttr(entityData, v)){
          if(v === 'field_application_url'){
            if(entityData.relationships && entityData.relationships['field_application_method'] && relationshipLike('external', entityData.relationships['field_application_method'].data, data.allTaxonomyTermApplicationMethods)){
              valid = false;
              setValidation("Required field");
            }else if(validation){
              setValidation(false);
            }
          }else if(v === 'field_required_documents'){
            if(entityData.relationships && entityData.relationships['field_application_method'] && relationshipLike('board', entityData.relationships['field_application_method'].data, data.allTaxonomyTermApplicationMethods)){
              valid = false;
              setValidation("Required field");
            }else if(validation){
              setValidation(false);
            }
          }else{
            valid = false;
            setValidation("Required field");
          }
        }
        else if(validation){
          setValidation(false);
        }
      }else if(allTextAreasKeys.includes(v)){
        if(!hasValidTextareaValue(entityData, v)){
          valid = false;
          setValidation("Required field");
        }
        else if(validation){
          setValidation(false);
        }
      }else if(requiredRelationships.includes(v)){
        if(!hasRel(entityData, v)){
          valid = false;
          if(v === 'field_category'){
            setValidation("Must select at least one category");
          }
          else{
            setValidation("Required field");
          }
        }
        else if(validation){
          setValidation(false);
        }
      }else if(is_salary_range){
        if(hasRel(entityData, 'field_salary_type')){
          const is_max = v === 'field_salary_number_max'
          const min_sal = entityData.attributes['field_salary_number'] ? Math.abs(entityData.attributes['field_salary_number']) : null;
          const max_sal = entityData.attributes['field_salary_number_max'] ? Math.abs(entityData.attributes['field_salary_number_max']) : null;
          if(is_max && (!max_sal || (max_sal && max_sal < 0))){
            setValidation("Please add a maximum value");
          }else if(!is_max && (!min_sal || (min_sal && min_sal < 0))){
            setValidation("Please add a minimum value");
          }else if(min_sal && max_sal){
            if(max_sal < min_sal){
              setValidation("Invalid range");
            }
          }
        }
      }else if(v === 'field_location'){
        if(geolocation.length !== 1){
          valid = false;
          setValidation("Must enter an address, city or postal code that matches a single location")
        }
        else if(validation){
          setValidation(false);
        }
      }
    }
    return valid;
  }