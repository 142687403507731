import React from 'react'
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

function Logo() {
    return <StaticQuery
    query={graphql`
      query GET_LOGO {
        allFile(filter: {base: {eq: "logo.svg"}}) {
          edges {
            node {
              publicURL
              url
            }
          }
        }
      }
    `}
    render={data => {
      return <img className="odds-logo-image" src={data.allFile.edges[0].node.publicURL} alt="Impact Oregon - Careers that make a difference" />
    }}
    ></StaticQuery>
}

export default Logo
