import React from 'react';
import Meta from 'gatsby-theme-carbon/src/components/Meta';
import { useMetadata } from 'gatsby-theme-carbon/src/util/hooks';

const CustomMeta = (props) => {
    const { title } = useMetadata();
    const title_lbl = props.pageTitle || title
    const append = props.pageTitle ? '| Careers that make a difference | Impact Oregon' : ''
    return <Meta {...props} pageTitle={`${title_lbl} ${append}`} />
}

export default CustomMeta;