export default (field) => {
	let html = field && field.processed ? field.processed.split(/<h2\b[^>]*>/) : null;
	let result = []
	if(html){
		for (const block_string of html) {
			let block_parts = block_string.split(/<\/h2\b[^>]*>/)
			let title = block_parts.length === 2 ? block_parts[0] : ''
			let content = block_parts.length === 2 ? block_parts[1] : block_parts[0]
			if(title.length || content.length){
				result.push({
					title,
					content
				})
			}
		}
	}
	return result
}