import React, { useContext } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';
import NavContext from '../../../../node_modules/gatsby-theme-carbon/src/util/context/NavContext';
import {UserContext, handleLogout, isLoggedIn} from '@parallelpublicworks/entitysync';
import {Button} from 'carbon-components-react';
import { nav, open, divider, link, linkDisabled } from './Switcher.module.scss';
import { Launch16 } from '@carbon/icons-react';
import { sortMenuTree } from '../../../util';

const Switcher = ({ children }) => {
  const { switcherIsOpen } = useContext(NavContext);

  return (
    <nav
      className={cx(nav, { [open]: switcherIsOpen })}
      aria-label="Utility Navigation"
      aria-expanded={switcherIsOpen}
      tabIndex="-1"
    >
      <ul>{children}</ul>
    </nav>
  );
};

export const SwitcherDivider = props => (
  <li className={divider}>
    <span {...props} />
  </li>
);

export const SwitcherLink = ({
  disabled,
  external,
  children,
  href: hrefProp,
  ...rest
}) => {
  const href = disabled || !hrefProp ? undefined : hrefProp;
  const className = disabled ? linkDisabled : link;
  const { switcherIsOpen } = useContext(NavContext);
  let target = external ? '_blank' : '_self';
  let rel = external ? 'noopener noreferrer' : ''

  return (
    <li className="odds-switcher">
      <a
        {...rest}
        aria-disabled={disabled}
        role="button"
        tabIndex={switcherIsOpen ? 0 : '-1'}
        className={className}
        href={href}
        rel={rel}
        target={target}
      >
        {external && <div><Launch16 /></div>}
        <span>{children}</span>
      </a>
    </li>
  );
};

// https://css-tricks.com/using-css-transitions-auto-dimensions/
// Note: if you change this, update the max-height in the switcher stylesheet
const DefaultChildren = () => {
  
  const data = useStaticQuery(graphql`
    query {
      allMenuItems(filter: {menu_name: {eq: "utility"}}) {
        edges {
          node {
            id
            menu_name
            url
            title
            enabled
            description
            children {
              id
            }
          }
        }
      }
    }    
  `);

  const sortedSwitchers = sortMenuTree(data.allMenuItems.edges);

  const renderSwitchers = () => {
    return sortedSwitchers.map((switcher) => {
      if(!switcher.url.length){
        return <SwitcherDivider key={switcher.id}>{switcher.title}</SwitcherDivider>
      }else{
        return <SwitcherLink  key={switcher.id} href={switcher.url} external={switcher.url.includes('http')} >
          {switcher.title}
        </SwitcherLink>
      }
    })
  }

  const userContext = useContext(UserContext)
  
  const logout = (e) => {
    return handleLogout(userContext, e)
  }

  const goToLogin = (e) => {
    navigate('/login',
    {
      state: {referrer: typeof window !== `undefined` ? window.location.pathname : '/'} 
    });
  }

  return (
    <>
		<SwitcherDivider key='account'>Users</SwitcherDivider>
      { typeof userContext[0] !== `undefined` && isLoggedIn(userContext) ? 
        (<SwitcherLink onClick={logout}>Logout</SwitcherLink>) :
        (<SwitcherLink onClick={goToLogin}>Login</SwitcherLink>) 
      }
	  {renderSwitchers()}
    
    </>
  );
};

Switcher.defaultProps = {
  children: <DefaultChildren />,
};

export default Switcher;
