import React from 'react';
import { AnchorLink } from 'gatsby-theme-carbon';
import { jobContentFields, removeTags } from './'

export default (data) => {
  let anchors = []
  jobContentFields.forEach(field_conf => {
    let description_html = null
    if(data[field_conf.key]){
      if(data[field_conf.key].processed) description_html = data[field_conf.key].processed
      if(data[field_conf.key].value) description_html = data[field_conf.key].value
      if(removeTags(description_html).length > 0) anchors.push(<AnchorLink key={field_conf.anchor}>{field_conf.label}</AnchorLink>)
    }
  });
  return anchors
}