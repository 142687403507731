
module.exports = function isExpired(job_listing, expirationLengthTerms = null, autoExpirationName = null){
  /* https://stackoverflow.com/a/2706169 */
  function addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }
  if(!job_listing) return true;
  const attributes = job_listing.attributes ? job_listing.attributes : job_listing;
  const markedAsExpired = (attributes && attributes.field_expired) ? attributes.field_expired : false;
  if(!job_listing.relationships || !job_listing.relationships.field_expiration) return markedAsExpired;
  let expiredByTime = false;
  const expirationTermID = job_listing.relationships && job_listing.relationships.field_expiration && job_listing.relationships.field_expiration.data && job_listing.relationships.field_expiration.data.id ? job_listing.relationships.field_expiration.data.id : null;
  if(expirationLengthTerms && !autoExpirationName){
    const autoExpirationTerm = expirationLengthTerms.find((term) => {
      let term_id = term.drupal_id ? term.drupal_id : term.id
      return term_id === expirationTermID || term.name === autoExpirationName
    })
    if(autoExpirationTerm){
      autoExpirationName = autoExpirationTerm.attributes ? autoExpirationTerm.attributes.name : autoExpirationTerm.name
    }
  }
  if(autoExpirationName){
    const currentDate = new Date();
    let exprDateVal = null;
    if(attributes.field_publish_date){
      exprDateVal = Number.isInteger(attributes.field_publish_date) ? attributes.field_publish_date * 1000 : attributes.field_publish_date;      
    }
    const find_expirationLengthMonths = autoExpirationName ? autoExpirationName.match(/(\d+)/) : null;
    if(find_expirationLengthMonths){
      let expirationLengthMonths = find_expirationLengthMonths && find_expirationLengthMonths[0] ? parseInt(find_expirationLengthMonths[0]) : 0;
      if(expirationLengthMonths && exprDateVal){
        const expirationDate =  addMonths(new Date(exprDateVal), expirationLengthMonths);
        expiredByTime = currentDate > expirationDate;
      }
    }
  }
  let is_expired = markedAsExpired || expiredByTime;
  return is_expired;
}
