import './src/styles/index.scss';
import { UserContextProvider } from "@parallelpublicworks/entitysync";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import ProviderWrapper from './src/components/provider-wrapper';

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY} >
    <img height="1" width="1" style={{borderStyle: 'none', marginTop: -1}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=sjc5ukk&ct=0:bqgtjai&fmt=3"/>
    <UserContextProvider>
      { element }
    </UserContextProvider>
  </GoogleReCaptchaProvider>
);

export const wrapPageElement = ({ element, props }) => {
  let pageContext = { ...props.pageContext };
  if(props.location.pathname.includes('provider-landing')){
    return (
      <ProviderWrapper location={props.location} pageContext={pageContext}>
        {element}
      </ProviderWrapper>
    );
  }else{
    return <>{element}</>
  }
}