import {useState} from 'react'

export default (isRegistration = false) => {
    const validationState = {};
    validationState.field_contact_name = useState(false);
    validationState.field_organization_name = useState(false);
    validationState.field_phone = useState(false);
    validationState.field_website = useState(false);
    validationState.field_dba = useState(false);
    validationState.field_provider_or_express_id = useState(false);
    if(isRegistration){
        validationState.name = useState(false);
        validationState.mail = useState(false);
    }
    return validationState;
}
  