import React from 'react';
import { HomepageBanner, HomepageCallout , PageDescription, FeatureCard} from 'gatsby-theme-carbon';
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import { calloutLink, calloutP } from './Homepage.module.scss';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Logo from '../components/Logo'

const FirstRightText = () => (<>
  <h1 className={calloutP}><b>Impact Oregon connects job seekers with careers in the field of developmental disabilities services in Oregon.</b></h1>
  <p className={calloutP}><span>
      <Link
        className={calloutLink}
        to="/job-search"
      >
        Search job listings →
      </Link>
      <Link
        className={calloutLink}
        to="/about"
      >
        About ImpactOregon →
      </Link>
    </span></p>
</>
);

const SecondLeftText = () => <p>For Authorized <br /> Hiring Managers</p>;

const SecondRightText = () => (
  <p className={calloutP}>
     Authorized and approved job providers have access to advertise job opportunities in the development disabilities field. Request an account or log in to begin finding qualified candidates today!
     <span>
        <Link
          className={calloutLink}
          to="/request-access"
        >
          Request Access →
        </Link>
        <Link
          className={calloutLink}
          to="/provider-landing/submit-new-listing"
        >
          Log in →
        </Link>
     </span>
  </p>
);

const BannerText = () => <></>;


const customProps = {
  FirstCallout: (
    <HomepageCallout
      backgroundColor='$brand-01'
      color="white"
      leftText={Logo}
      rightText={FirstRightText}
    />
  ),
  SecondCallout: (
    <HomepageCallout
      leftText={SecondLeftText}
      rightText={SecondRightText}
      color="white"
      backgroundColor='$brand-01'
    />
  ),
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  
  return <StaticQuery
    query={graphql`
      query GET_IMAGE {
        header_image: file(relativePath: { eq: "P1400766.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        banner: file(relativePath: { eq: "home-mid-image.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}

    
    render={data => {
      return <HomepageTemplate {...customProps} {...props} color="light" Banner={<HomepageBanner renderText={BannerText} image={data.header_image.childImageSharp.fluid.src} />} >
        <div className="inner-content">
          <PageDescription>
            <p>Not sure what job is a good fit? Explore our career profiles that highlight various jobs within the field of developmental disabilities.</p>
          </PageDescription>
          <FeatureCard
              color="dark"
              href="/browse-careers"
              title="Explore career profiles"
              actionIcon="arrowRight"
              className="homepage-feature"
              >
              <Img fluid={data.banner.childImageSharp.fluid} alt="Developmental Disabilities Services Professional" />
          </FeatureCard>
        </div>
      </HomepageTemplate>
    }}
    ></StaticQuery>
}

export default ShadowedHomepage;
