// Gatsby doesn't include the recommended exceptions to this rule
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
// https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-noninteractive-element-to-interactive-role.md#rule-details

import React, { useEffect, createContext, useContext } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import {
  list,
  link,
  description,
  active,
  hidden,
  tab,
  divider
} from './GlobalSearch.module.scss';
import { removeTags } from '../../util';


export const MenuDivider = ({children}) => (
  <li className={`divider ${divider}`}>
    {children}
  </li>
);

export const MenuContext = createContext();

const Menu = ({ results, onKeyDown, query, toggleNavState, groupedResults }) => {
  const { optionsRef } = useContext(MenuContext);

  // clear the options list every render so it will always be fresh
  useEffect(() => {
    optionsRef.current = [];
    return () => (optionsRef.current = []);
  });

  return (
    <ul
      aria-labelledby="search-label"
      role="menu"
      id="search-menu"
      className={cx(list, {
        [hidden]: results.length === 0,
      })}>

      {groupedResults.filter(group => group.items.length > 0).map((group, group_index) => {
        let group_link = group.query_arg ? `${group.to}?${group.query_arg}=${query}` : group.to;
        return <>
          <MenuDivider><span>{group.label}</span><Link to={group_link} onClick={() => toggleNavState('searchIsOpen', 'close')} >View all</Link></MenuDivider>        
          {group.items.map((page, index) => {
            return <MenuItem
              id={`menu-item-${page.index}`}
              onKeyDown={onKeyDown}
              key={page.path}
              index={page.index}
              page={page}
            />
          })}
        </>
      })}

    </ul>
  );
};

const MenuItem = ({ page, index, onKeyDown, id }) => {
  const { optionsRef, focusedItem, clearAndClose } = useContext(MenuContext);

  useEffect(() => {
    optionsRef.current.push(page.path);
  });

  const className = cx(link, {
    [active]: focusedItem === index,
  });

  return (
    <li role="none" key={page.path} className="menu-item" >
      <Link
        onClick={clearAndClose}
        onKeyDown={onKeyDown}
        tabIndex="-1"
        id={id}
        role="menuitem"
        className={className}
        to={page.path}>
        {page.title}&nbsp;
        {page.tab && <span className={tab}>→ {page.tab} </span>}
        {page.body && (
          <span className={description}>– {removeTags(page.body)}</span>
        )}
      </Link>
    </li>
  );
};

export default Menu;
