// https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.1pattern/listbox-combo.html/#ex1
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/role-has-required-aria-props */

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { Close20, Search20 } from '@carbon/icons-react';
import _throttle from 'lodash.throttle';
import { navigate, graphql, useStaticQuery } from 'gatsby';
// import { Index } from "lunr"
import { useLunr } from 'react-lunr'
import cx from 'classnames';
import NavContext from 'gatsby-theme-carbon/src/util/context/NavContext';
import { useOnClickOutside } from 'gatsby-theme-carbon/src/util/hooks';

import {
  container,
  input,
  label,
  searchButton,
  searchButtonClose,
  inputWrapper,
  inputFocusWithin,
  hidden,
  inactive,
} from 'gatsby-theme-carbon/src/components/GlobalSearch/GlobalSearch.module.scss';

import Menu, { MenuContext } from './Menu';


// TODO pass magnifying ref for escape/close? keep focus within outline for input,
const GlobalSearchInput = () => {


  const data = useStaticQuery(graphql`
    {
      localSearchPages {
        index
        store
      }
    }
  `);

  const optionsRef = useRef([]);
  const [focusedItem, setFocusedItem] = useState(0);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const openButtonRef = useRef(null);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [query, setQuery] = useState('');
  const results = useLunr(query, data.localSearchPages.index, data.localSearchPages.store);
  const groupLimit = 5;
  const [filteredResults, setFilteredResults] = useState([])
  const [groupedResults, setGroupedResults] = useState([])
  const [sortedResults, setSortedResults] = useState([])

  useEffect(() => {
    setFilteredResults(results.filter((result) => result.title))
  }, [results])

  useEffect(() => {
    let grouped = [
      {
        type: 'node__job_listing',
        label: 'Job Listings',
        to: '/job-search',
        query_arg: 'search',
        items: []
      },
      {
        type: 'node__success_story',
        label: 'Success Stories',
        to: '/success-stories',
        items: []
      },
      {
        type: 'node__career',
        label: 'Careers',
        to: '/browse-careers',
        items: []
      },
    ];
    filteredResults.forEach(result => {
      grouped.forEach((group, group_index) => {
        if(result.type === group.type) {
          if(grouped[group_index].items.length < groupLimit){
            grouped[group_index].items.push(result);
          }
        }
      })
    });
    let index_count = 0;
    grouped.forEach((group, group_index) => {
      group.items.forEach((result, index) => {
        grouped[group_index].items[index].index = index_count;
        index_count++;
      })
    })
    setGroupedResults(grouped)
  }, [filteredResults])

  useEffect(() => {
    let sorted = []
    groupedResults.forEach((group) => {
      group.items.forEach((result) => {
        sorted.push(result);
      })
    })
    setSortedResults(sorted);
  }, [groupedResults])

  const {
    toggleNavState,
    searchIsOpen,
    isManagingFocus,
    setIsManagingFocus,
  } = useContext(NavContext);

  const clearAndClose = useCallback(() => {
    setQuery('');
    toggleNavState('searchIsOpen', 'close');
    if (openButtonRef.current && isManagingFocus) {
      openButtonRef.current.focus();
    }
    
  }, [isManagingFocus, toggleNavState]);

  const value = useMemo(
    () => ({ optionsRef, focusedItem, setFocusedItem, clearAndClose }),
    [clearAndClose, focusedItem]
  );

  useEffect(() => {
    if (inputRef.current && searchIsOpen) {
      inputRef.current.focus();
      setInputIsFocused(true);
    }
  }, [searchIsOpen]);

  useOnClickOutside(containerRef, () => {
    toggleNavState('searchIsOpen', 'close');
    setQuery('');
  });

  useEffect(() => {
    setFocusedItem(0)
  }, [query]);

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        setIsManagingFocus(true);
        setFocusedItem((focusedItem + 1) % sortedResults.length);
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        setIsManagingFocus(true);
        if (focusedItem - 1 < 0) {
          setFocusedItem(sortedResults.length - 1);
        } else {
          setFocusedItem(focusedItem - 1);
        }
        break;
      }
      case 'Escape': {
        e.preventDefault();
        setFocusedItem(0);
        if (query === '') {
          clearAndClose();
        } else {
          setQuery('');
          setIsManagingFocus(true);
          inputRef.current.focus();
        }
        break;
      }
      case 'Enter': {
        e.preventDefault();
        if (sortedResults[focusedItem]) {
          navigate(sortedResults[focusedItem].path);
        }
        break;
      }
      default:
    }
  };

  // Check if there are results, if there are the listbox is open
  // and set focus to the first menu item
  const getAriaActiveDescendantValue = () => {
    if (sortedResults.length > 0) {
      return `menu-item-${focusedItem}`;
    }

    return null;
  };

  return (
    <MenuContext.Provider value={value}>
      <div
        ref={containerRef}
        className={cx(container, {
          [hidden]: !searchIsOpen,
          [inputFocusWithin]: inputIsFocused,
        })}
        aria-label="Global search"
        role="search">
        <label htmlFor="search-input" id="search-label" className={label}>
          Search
        </label>
        <div
          className={inputWrapper}
          aria-owns="search-menu"
          aria-haspopup="menu"
          aria-expanded={searchIsOpen}>
          <button
            tabIndex={searchIsOpen ? '-1' : '0'}
            className={cx(searchButton, {
              [inactive]: searchIsOpen,
            })}
            ref={openButtonRef}
            type="button"
            aria-label="Open search"
            onClick={() => {
              toggleNavState('searchIsOpen', 'open');
              toggleNavState('switcherIsOpen', 'close');
            }}>
            <Search20 description="Open search" />
          </button>
          <input
            autoComplete="off"
            tabIndex={searchIsOpen ? '0' : '-1'}
            onBlur={() => setInputIsFocused(false)}
            onFocus={() => setInputIsFocused(true)}
            ref={inputRef}
            type="text"
            aria-autocomplete="list"
            aria-controls="search-menu"
            aria-activedescendant={getAriaActiveDescendantValue()}
            className={cx(input, {
              [hidden]: !searchIsOpen,
            })}
            aria-label="Site wide search input"
            id="search-input"
            placeholder="Search"
            value={query}
            onKeyDown={onKeyDown}
            onChange={(evt) => setQuery(evt.target.value)}
          />
          <button
            tabIndex={searchIsOpen ? '0' : '-1'}
            className={cx(searchButton, searchButtonClose, {
              [hidden]: !searchIsOpen,
            })}
            type="button"
            aria-label="Clear search"
            onClick={clearAndClose}>
            <Close20 description="Clear search" />
          </button>
        </div>
        <Menu onKeyDown={onKeyDown} results={sortedResults} query={query} toggleNavState={toggleNavState} groupedResults={groupedResults} />
      </div>
    </MenuContext.Provider>
  );
};

export default GlobalSearchInput;
