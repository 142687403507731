import React, {useContext} from 'react';
import ResourceLinks from 'gatsby-theme-carbon/src/components/LeftNav/ResourceLinks';
import { useStaticQuery, graphql } from 'gatsby';
import {UserContext, isLoggedIn} from '@parallelpublicworks/entitysync';

// shouldOpenNewTabs: true if outbound links should open in a new tab
const CustomResources = () => {

  const userContext = useContext(UserContext)

  const data = useStaticQuery(graphql`
    query {
      allMenuItems(filter: {menu_name: {eq: "secondary"}}) {
        edges {
          node {
            id
            menu_name
            url
            title
            enabled
            description
          }
        }
      }
    }    
  `);

  let links = data.allMenuItems.edges.map(({node}) => {
    return {
      title: node.title,
      href: node.url
    }
  })

  if(!userContext[0] || (typeof userContext[0] !== `undefined` && !isLoggedIn(userContext))){
    links.push({
      title: 'Request Access',
      href: '/request-access'
    })
  }

  return <ResourceLinks shouldOpenNewTabs links={links} />

};

export default CustomResources;
