import { getTermFromID, isExpired, getPostedLabel, getAddress, getSalary, getExperience, getCategoryLinks, getJobContent, getJobAnchors, getJobApplyMethod } from './';

export default (jobObject, data, objectType) => {
    let job_data = {
        ...jobObject.attributes,
        title: jobObject.attributes.title,
        address: getAddress(jobObject.attributes.field_address),
        posted: getPostedLabel(jobObject.attributes.field_publish_date),
        content: getJobContent(jobObject.attributes),
        anchors: getJobAnchors(jobObject.attributes),
        education: {name: ''},
        bilingual: {name: ''},
        position: {name: ''},
        is_expired: isExpired(jobObject, data.allTaxonomyTermExpirationLengths.nodes)
    };
    if(jobObject.relationships.field_education && data.allTaxonomyTermEducationLevels){
        job_data.education = getTermFromID(jobObject.relationships.field_education.data, data.allTaxonomyTermEducationLevels);
    }
    if(jobObject.relationships.field_bilingual && data.allTaxonomyTermBilingual){
        job_data.bilingual = getTermFromID(jobObject.relationships.field_bilingual.data, data.allTaxonomyTermBilingual);
    }
    if(jobObject.relationships.field_position && data.allTaxonomyTermPositionTypes){
        job_data.position = jobObject.relationships.field_position.data.map((ptype) => getTermFromID(ptype.id, data.allTaxonomyTermPositionTypes).name).join(', ');
    }
    if(jobObject.relationships.field_category && data.allTaxonomyTermCategories && Array.isArray(jobObject.relationships.field_category.data)) {
        job_data.category_links = getCategoryLinks(jobObject.relationships.field_category.data.map((cat) => getTermFromID(cat.id, data.allTaxonomyTermCategories)), objectType === 'localstorage');
    }
    if(data.allTaxonomyTermSalaryTypes && jobObject.attributes.field_salary_number && jobObject.relationships.field_salary_type){
        job_data.salary =  getSalary(jobObject.attributes.field_salary_number, getTermFromID(jobObject.relationships.field_salary_type.data, data.allTaxonomyTermSalaryTypes), jobObject.attributes.field_salary_number_max );
    }
    if(jobObject.relationships.field_experience_level || jobObject.relationships.field_experience_years){
        let level = jobObject.relationships.field_experience_level && data.allTaxonomyTermExperienceLevels ? getTermFromID(jobObject.relationships.field_experience_level.data, data.allTaxonomyTermExperienceLevels) : null;
        let years = jobObject.relationships.field_experience_years && data.allTaxonomyTermExperienceYears ? getTermFromID(jobObject.relationships.field_experience_years.data, data.allTaxonomyTermExperienceYears) : null;
        job_data.experience = getExperience(level, years)
    }
    if(jobObject.relationships.field_application_method && data.allTaxonomyTermApplicationMethods){
        job_data.method = getJobApplyMethod(getTermFromID(jobObject.relationships.field_application_method.data, data.allTaxonomyTermApplicationMethods), jobObject.attributes.field_required_documents, jobObject.attributes.field_application_url);
    }
    return job_data;
}