import React from 'react';
import he from 'he';
import { H3 } from 'gatsby-theme-carbon/src/components/markdown';
import { jobContentFields, removeTags } from './'
import { DOMParser, XMLSerializer } from 'xmldom';

function htmlDecode(input){
    return he.decode(input);
}

export default (data) => {
    let blocks = []
    jobContentFields.forEach(field_conf => {
        let description_html = null
        if(data[field_conf.key]){
            if(data[field_conf.key].processed) description_html = data[field_conf.key].processed
            if(data[field_conf.key].value) description_html = data[field_conf.key].value
        }
        if(description_html && removeTags(description_html).length > 0){

            const parser = new DOMParser();
            const XMLS = new XMLSerializer();
            const htmlText = htmlDecode(description_html);
            let content = parser.parseFromString(htmlText, "text/html");
            const anchors = content.getElementsByTagName('a');

            Array.from(anchors).forEach(a => {
                a.setAttribute("target", "_blank");
            });

            blocks.push(<div key={field_conf.anchor}><H3>{field_conf.label}</H3><div className="richtext-body" dangerouslySetInnerHTML={{__html: XMLS.serializeToString(content)}} /></div>)
        }
    });
    return blocks
}
