import Default from 'gatsby-theme-carbon/src/templates/Default';
import {Entity, UserContext, UserContextProvider, isLoggedIn} from '@parallelpublicworks/entitysync'
import React, {useContext, useState, useEffect} from 'react';
import { navigate, Link, graphql } from "gatsby"

const LoginWrap = ({children, location}) => {
  const userContext = useContext(UserContext);
  // console.log(location);
  if (!isLoggedIn(userContext)) {
    navigate('/login',
    {
      state: {referrer: location.pathname} 
    });
    return (<></>);
  } else {
    return children; 
  }
}

export default LoginWrap;
