import React from 'react';
export default (address_data) => {
    if(!address_data) return null
    let address = []
    if(address_data.address_line1) address.push(<span key="address_line1">{address_data.address_line1}</span>)
    if(address_data.address_line2) address.push(<span key="address_line2">{' '}{address_data.address_line2}</span>)
    if(address_data.address_line1 || address_data.address_line2) address.push(<span>{', '}</span>)
    if(address_data.locality) address.push(<span key="locality">{address_data.locality}</span>)
    if(address_data.administrative_area) address.push(<span key="administrative_area">{' '}{address_data.administrative_area}</span>)
    return address
}
