import * as mandrill from 'mandrill-api/mandrill'

export default (formData, job) => {
  const key = process.env.GATSBY_MANDRILL_API_KEY
  if (!key || !job.field_org_email) {
    return false
  }
	const mandrill_client = new mandrill.Mandrill(key);
	var message = {
			"html": `<p>${formData.applicantName} has applied for ${job.title}, their email is: ${formData.email}</p>`,
			"subject": `Application for ${job.title}`,
			"from_email": "connect@parallelpublicworks.com",
			"from_name": "Oregon Impact Employment First",
			"to": [{
							"email": job.field_org_email,
							"name": "Hiring Manager",
							"type": "to"
					}],
			"headers": {
					"Reply-To": "connect@parallelpublicworks.com"
			},
			"important": false,
			"track_opens": null,
			"track_clicks": null,
			"auto_text": null,
			"auto_html": null,
			"inline_css": null,
			"url_strip_qs": null,
			"preserve_recipients": null,
			"view_content_link": null,
			"tracking_domain": null,
			"signing_domain": null,
			"return_path_domain": null,
			"tags": [
					"application-received"
			],
			"metadata": {
					"website": "impactoregon.careers"
			},
			"attachments": formData.attachments
	};
	const async = false;
	const ip_pool = "Main Pool";
	mandrill_client.messages.send({"message": message, "async": async, "ip_pool": ip_pool}, function(result) {
			console.log('email sent');
	}, function(e) {
			// Mandrill returns the error as an object with name and message keys
			console.log('A mandrill error occurred: ' + e.name + ' - ' + e.message);
			// A mandrill error occurred: Unknown_Subaccount - No subaccount exists with the id 'customer-123'
	});
}
