import slugify  from './slugify';
export default (node) => {
    let slug = slugify(node.title)
    let thumbnail_url = node.relationships.field_story_thumbnail && node.relationships.field_story_thumbnail.localFile ? node.relationships.field_story_thumbnail.localFile.url : '';
    let career_title = node.relationships.field_story_career ? node.relationships.field_story_career.title : '';
    let thumbnail_alt = node.field_story_thumbnail && node.field_story_thumbnail.alt && node.field_story_thumbnail.alt.length > 0 ? node.field_story_thumbnail.alt : '';
    if(thumbnail_alt.length === 0 && thumbnail_url.length > 0) thumbnail_alt = node.relationships.field_story_thumbnail.localFile.name;
    let story = {
        ...node,
        nid: node.drupal_internal__nid,
        image: null,
        path: `/stories/${slug}`,
        career: career_title,
        thumbnail: thumbnail_url,
        alt: thumbnail_alt
    }

    return story;
}