export default (edges) => {
    edges = edges.map(e => e.node)

    let dividers = [...edges]
    let links = []
    let response = []

    for (const item of edges){
        links = [...links, ...item.children.map(i=>(i.id))]   
    }

    for (const link of links){
        dividers = dividers.filter(i => i.id !== link)
    }

    for (const divider of dividers) {
        response = [...response, divider]
        for (const link of divider.children){
            response = [...response, edges.find(c => link.id === c.id)]
        }
    }

    return response
}