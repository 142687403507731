import { jobContentFields, removeTags, trimSummary } from './'
export default (data) => {
    let desc = ''
    jobContentFields.forEach(field_conf => {
        let field_desc = ''
        if(data[field_conf.key]){
            if(data[field_conf.key].processed) field_desc = removeTags(data[field_conf.key].processed)
        }
        if(field_desc.length > 0){
            desc += ` ${field_desc}`;
        }
    });
    return trimSummary(desc, 165);
}
