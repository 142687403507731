import { useStaticQuery, graphql } from 'gatsby';

export function useNavItems() {
  const data = useStaticQuery(graphql`
    query {
      allMenuItems(filter: {menu_name: {eq: "main"}}) {
        edges {
          node {
            id
            menu_name
            url
            title
            enabled
            description
          }
        }
      }
    }    
  `);

  let navItems = data.allMenuItems.edges.map(({ node }) => {
    return {
      title: node.title,
      pages: [{
        title: node.title,
        path: node.url
      }],
    }
  });
  return navItems;
}
