import Default from 'gatsby-theme-carbon/src/templates/Default';
import { UserContext, isLoggedIn} from '@parallelpublicworks/entitysync'
import { CurrentUserEntity } from "../components/carbon-entitysync";
import LoginWrap from "../components/auth-wrapper";
import React, {useContext, useState, useEffect} from 'react';
import { retrieveUid, retrieveUserData } from "../util";
import { Loading } from 'carbon-components-react';

export const ProviderContext = React.createContext({
  userContext: null
});

export default function ProviderWrapper({pageContext, location, children}){
  const userContext = useContext(UserContext);
  const [displayName, setDisplayName] = useState(null);

  useEffect(() => {
    if(!displayName && userContext && userContext[0] && userContext[0].currentUserId && userContext[0].userData && userContext[0].userData[userContext[0].currentUserId]){
      let udata = userContext[0].userData[userContext[0].currentUserId]
      let display_name = udata.attributes.field_organization_name && udata.attributes.field_organization_name.length > 0 ? udata.attributes.field_organization_name : udata.attributes.display_name && udata.attributes.display_name.length > 0 ? udata.attributes.display_name : '';
      setDisplayName(display_name)
    }
  }, [userContext])

  if(typeof window === 'undefined'){
    return (<></>);
  }

  pageContext = {...pageContext, frontmatter:
      {
        title: displayName,
        tabs: ['Submit new listing', 'Current listings', 'Expired', 'Account details']
      }
  };

  return (
    <LoginWrap location={location}>
      <Default pageContext={pageContext} location={location}>
        <CurrentUserEntity>
          <ProviderContext.Provider value={{
            userContext
          }}>
           {children}
          </ProviderContext.Provider>
        </CurrentUserEntity>
      </Default>
    </LoginWrap>
  );
}

