import {getDateFromAttribute, isExpired, mapGatsbyNode} from "./";

export default (_userData, data, uid, showExpired=false) => {
    let nextListings = [];
    if(data){
      // add nodes retrieved from gatsby to the ones in the _userData
      for(let node of data.allNodeJobListing.nodes){
        const mappedNode = mapGatsbyNode(node);
        if(!_userData[mappedNode.id] || !_userData[mappedNode.id].attributes || !_userData[mappedNode.id].attributes.changed ||  !mappedNode.attributes || !mappedNode.attributes.changed || getDateFromAttribute(_userData[mappedNode.id].attributes.changed) <= getDateFromAttribute(mappedNode.attributes.changed) )
          // only set the _userData with the gatsby node if the gatsby node is more recent
        _userData[mappedNode.id] = mappedNode;
      }
    }
    for(let id of Object.keys(_userData)){
      let expiredCondition = isExpired(_userData[id], data.allTaxonomyTermExpirationLengths.nodes.map(mapGatsbyNode));
      expiredCondition = showExpired ? expiredCondition : !expiredCondition;
      let item = _userData[id];
      if(item.type &&
        item.type === 'node--job_listing' &&
        uid && item.relationships && item.relationships.uid && item.relationships.uid.data && item.relationships.uid.data.id && uid === item.relationships.uid.data.id &&
        expiredCondition && item.attributes && !item.attributes.field_archived){
          let timestamp = typeof item.attributes.field_publish_date == 'string' ? new Date(item.attributes.field_publish_date).getTime() : item.attributes.field_publish_date * 1000;
          nextListings.push({id, timestamp});
      }
    }
    nextListings = nextListings.sort((a,b)=>b.timestamp - a.timestamp)
    return nextListings;
  }
  