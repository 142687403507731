export default (node) => {
  const ret = {
    attributes: {},
    relationships: {},
  };
  for(let key of Object.keys(node)){
    if(!['relationships', 'drupal_id', 'type'].includes(key)){
      const value = node[key] && node[key].value ? { value: node[key].value } : node[key];

      ret.attributes[key] = value;
    }
  }

  const rels = node.relationships;
  if(rels){
    for(let relKey of Object.keys(rels)){
      if(!rels[relKey] || !(rels[relKey].drupal_id || Array.isArray(rels[relKey]))) continue;
      if(Array.isArray(rels[relKey])){
        ret.relationships[relKey] = {
          data: rels[relKey].map((r) =>{
            return { id: r.drupal_id };
          })
        };
      }
      else{
        ret.relationships[relKey] = {
          data: {
            id: rels[relKey].drupal_id,
          }
        };
      }
    }
  }
  if(node.drupal_id){
    ret.id = node.drupal_id;
  }
  ret.type = 'node--job_listing';
  // console.log('MAP GATSBY NODE', node, ret);
  return ret;
}
