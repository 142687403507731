import { currencyFormat, removeTags} from './';
export default (number, type, number_max)  => {
    if(!type || !type.description) return null
    let label = null
    if(number) label = currencyFormat.format(Math.abs(number))
    if(number_max) label += ` - ${currencyFormat.format(Math.abs(number_max))}`
    if(type && number){
        label += ' '
        label += type.description.processed ? removeTags(type.description.processed) : type.name
    }
    return label
}
