import {getDateFromAttribute, isExpired, mapGatsbyNode, removeTags} from "./";
import { isArray } from "validate.js";


function mapEntityToIndexable(entity, attributes, relationships, data){
  const indexable = {};
  indexable.id = entity.id ? entity.id : entity.local_id;
  for(let attr of attributes){
    let indexable_attr =
      entity.attributes && entity.attributes[attr] ?
        entity.attributes[attr].value ?
        removeTags(entity.attributes[attr].value) : removeTags(entity.attributes[attr]) : "";
    if(indexable_attr) {
      indexable[attr] = indexable_attr
    }
  }
  for(let rel of relationships){
    let index_string = ''
    let index_data = entity.relationships && entity.relationships[rel.field] ? entity.relationships[rel.field].data : null;
    if(isArray(index_data)){
      for (let i = 0; i < index_data.length; i++) {
        if(index_data[i] && index_data[i].id && data[rel.term_query] && data[rel.term_query].nodes){
          let term = data[rel.term_query].nodes.filter(({drupal_id}) => drupal_id === index_data[i].id)
          if(term && term[0]) index_string += ` ${term[0].name}`
        }
      }
    }else{
      if(index_data && index_data.id && data[rel.term_query] && data[rel.term_query].nodes){
        let term = data[rel.term_query].nodes.filter(({drupal_id}) => drupal_id === index_data.id)
        if(term && term[0]) index_string = term[0].name;
      }
    }
    indexable[rel.field] = index_string
  }
  return indexable;
}

export default (userData, data, uid=null, showExpired=false, index=null, indexedAttributes=null, indexedRelationships=null) => {
  const nextListings = [];
  if(data){
    // add nodes retrieved from gatsby to the ones in the sortedUserData
    for(let node of data.allNodeJobListing.nodes){
      const mappedNode = mapGatsbyNode(node);
      mappedNode.gatsbyPublished = true;
      if(!userData[mappedNode.id] || !userData[mappedNode.id].attributes || !userData[mappedNode.id].attributes.changed ||
        !mappedNode.attributes || !mappedNode.attributes.changed ||
        getDateFromAttribute(userData[mappedNode.id].attributes.changed) <= getDateFromAttribute(mappedNode.attributes.changed)
      )
      // only set the sortedUserData with the gatsby node if the gatsby node is more recent
        mappedNode.gatsbyPublished = true;
        userData[mappedNode.id] = mappedNode;
    }
  }
  for(let id of Object.keys(userData)){
    let is_expired = isExpired(userData[id], data.allTaxonomyTermExpirationLengths.nodes.map(mapGatsbyNode));
    const isMatchingUid = !uid ||
      userData[id].relationships &&
      userData[id].relationships.uid &&
      userData[id].relationships.uid.data &&
      userData[id].relationships.uid.data.id &&
      uid === userData[id].relationships.uid.data.id;

    let expiredCondition = showExpired ? is_expired : !is_expired;
    if(userData[id].type && userData[id].type === 'node--job_listing' && isMatchingUid && expiredCondition && userData[id].attributes && !userData[id].attributes.field_archived){
      if(index && indexedAttributes){
        index.addDoc(mapEntityToIndexable(userData[id], indexedAttributes, indexedRelationships, data));
      }
      let timestamp = typeof userData[id].attributes.field_publish_date == 'string' ? new Date(userData[id].attributes.field_publish_date).getTime() : userData[id].attributes.field_publish_date * 1000;
      nextListings.push({id: id, timestamp});
    }
  }
  return nextListings;
}

