import {useState} from 'react' 

export default () => {
    const validationState = {};
    validationState.title = useState(false);
    validationState.field_company_name = useState(false);
    validationState.field_category = useState(false);
    validationState.field_address = useState(false);
    validationState.field_description = useState(false);
    validationState.field_required_qualifications = useState(false);
    validationState.field_preferred_qualifications = useState(false);
    validationState.field_benefits = useState(false);
    validationState.field_education = useState(false);
    validationState.field_bilingual = useState(false);
    validationState.field_experience_level = useState(false);
    validationState.field_experience_years = useState(false);
    validationState.field_salary_type = useState(false);
    validationState.field_salary_number = useState(false);
    validationState.field_salary_number_max = useState(false);
    validationState.field_position = useState(false);
    validationState.field_application_method = useState(false);
    validationState.field_application_url = useState(false);
    validationState.field_required_documents = useState(false);
    validationState.field_expiration = useState(false);
    validationState.field_location = useState(false);
    return validationState;
}
