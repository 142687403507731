import React from 'react';
import slugify from './slugify';
import { Link } from 'gatsby';

export default (categories, blankLinks) => {
    let links = categories.map(({name}) => {
        let url = blankLinks ? null : `/job-search?category=${slugify(name)}`
        return <><Link href={url}><strong>{name}</strong></Link>{' '}</>
    })
    return links.length > 0 ? links : null
}