import React from 'react';
import { Row, Grid, Column } from 'gatsby-theme-carbon';
import {
  footer,
  grid,
  content,
  nav,
  statement,
  listItem
} from './Footer.module.scss';
import LogoFooter from './../LogoFooter'
import {Link} from 'gatsby'

let isStaging = true
if (typeof window !== 'undefined') {
  isStaging = window.location.hostname.includes('stage') || window.location.hostname.includes('testing');
}

const Footer = () => {
  let pixelCode = `ttd_dom_ready( function() {
      if (typeof TTDUniversalPixelApi === 'function') {
        var universalPixelApi = new TTDUniversalPixelApi();
        universalPixelApi.init("sjc5ukk", ["v0yj7ap"], "https://insight.adsrvr.org/track/up");
      }
    });`;
  return (
    <footer id="odds-footer" className={footer}>
      <Grid className={grid}>
        <Row>
          <Column colLg={4} colMd={4}>
            <Link to="/" aria-label="Navigate to home page"><LogoFooter /></Link>
            <ul className={nav}>
              <li key='about-link' className={listItem}>
                <Link to="/about">About ImpactOregon</Link>
              </li>
            </ul>
            <p className={statement}>The Office of Developmental Disabilities Services and the Oregon Department of Human Services value an inclusive workforce. The developmental disabilities community and those that work in this field are committed to attracting and retaining a diverse staff.</p>
          </Column>
          <Column
            className={content}
            colLg={4}
            colMd={4}
            colSm={3}
            offsetLg={2}>
              <>
                <p>
                  Oregon Department of Human Services <br />
                  500 Summer St. NE E-15 <br />
                  Salem, OR 97301 <br />
                </p>
                <p>
                Safety, health and independence for all Oregonians
                </p>
                <p><Link to="/legal-disclaimers">Legal Disclaimers</Link></p>
              </>
          </Column>
        </Row>
      </Grid>
    {isStaging ? <></>
    :
    <>
      <script src="https://js.adsrvr.org/up_loader.1.1.0.js" type="text/javascript"></script>
      <script type="text/javascript" dangerouslySetInnerHTML={{ __html: pixelCode }}></script>
    </>
    }
    </footer>
  );
};

export default Footer;
