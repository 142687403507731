import React, {useContext, useRef, useState, useEffect} from 'react';
import { FormGroup, TextInput, NumberInput, TextArea, Select, Button, MultiSelect, FormLabel, Checkbox, Modal, Loading } from 'carbon-components-react';
import { RelationshipContext, Relationship, FieldContext, Field, SubmitButtonComponent, Entity, EntityContext, UserContext } from '@parallelpublicworks/entitysync';
import { removeTags, trimSummary, isEmptyObject, getPostedLabel } from './../util';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LeftNav from "../gatsby-theme-carbon/components/LeftNav/LeftNav";


function TextFieldComponent(props){
  const context = props.isRelationship ? RelationshipContext : FieldContext;
  const [val, setVal] = useContext(context);
  const onChange = props.onChange ? (e) => {
    props.onChange(e);
    setVal(e.target.value)
  } : (e) => setVal(e.target.value);
  let _props = {...props}
  delete _props.isRelationship
  delete _props.isUrl
  let input_val = props.isUrl && val && !isEmptyObject(val) && val.uri ? val.uri : val && !isEmptyObject(val) ? val : "";
  return (
    <TextInput {..._props} onChange={onChange} value={input_val} id={props.id} />
  );
}
export function TextField({field, ...props}){
  return (
    <Field field={field}>
      <TextFieldComponent {...props} isRelationship={false} id={field} />
    </Field>
  );
}

function NumberFieldComponent({...props}){
  const context = props.isRelationship ? RelationshipContext : FieldContext;
  const [val, setVal] = useContext(context);
  const onChange = (e) => {
    let new_val = typeof e.imaginaryTarget !== 'undefined' ? e.imaginaryTarget.value : e.target.value;
    setVal(new_val)
    if(props.onChange) props.onChange(e)
  }
  let _props = {...props}
  delete _props.isRelationship
  delete _props.isUrl
  return (
    <NumberInput {..._props} min={0} onChange={onChange} value={val && !isEmptyObject(val) ? val : ""} id={props.id} />
  );

}
export function NumberField({field, ...props}){
  return (
    <Field field={field}>
      <NumberFieldComponent {...props} isRelationship={false}  id={field} />
    </Field>
  );
}

function TextSubFieldComponent(props){
  const context = props.isRelationship ? RelationshipContext : FieldContext;
  const [val, setVal] = useContext(context);
  const onChange = props.onChange ? (e) => {
    props.onChange(e);
    setVal(e.target.value)
  } : (e) => setVal(e.target.value);
  return (
    <TextInput {...props} onChange={onChange} value={val ? val : ""}/>
  );
}

export function TextSubField({subField, ...props}){
  const context = props.isRelationship ? RelationshipContext : FieldContext;
  const [val, setVal] = useContext(context);
  const onChange = props.onChange ? (e) => {
    props.onChange(e);
    setVal({...val, [subField]: e.target.value})
  } : (e) => setVal({...val, [subField]: e.target.value});
  let _props = {...props}
  delete _props.isRelationship
  return (
    <TextInput {..._props} id={subField}  onChange={onChange} value={  val && val[subField] ? val[subField] : ""}/>
  );
}



function SelectComponent({isRelationship, relType, field, ...props}){
  const context = isRelationship ? RelationshipContext : FieldContext;
  const makeFieldValue = (val) => {
    let fieldVal;
    if(isRelationship){
      fieldVal = {
        id: val
      };
      if(relType) fieldVal.type = relType;
    }
    else {
      fieldVal = val;
    }
    return fieldVal
  };
  const getFieldValue = (fieldValueObj) => {
    if(isRelationship &&
      fieldValueObj.relationships &&
      fieldValueObj.relationships[field] &&
      fieldValueObj.relationships[field].data &&
      fieldValueObj.relationships[field].data.id){
      return fieldValueObj.relationships[field].data.id;
    }
    else if(!isRelationship &&
      fieldValueObj.attributes &&
      fieldValueObj.attributes[field]){
      return fieldValueObj.attributes[field];
    }
    return null;
  };
  const [fieldValueObj, setFieldValue] = useContext(context);
  const fieldValue = getFieldValue(fieldValueObj);
  if(fieldValue){
    props.value = fieldValue;
  }
  const onChange = typeof props.onChange !== 'undefined' ? (e) => {
    setFieldValue( makeFieldValue(e.target.value), );
    props.onChange(e);
  } : (e) => { setFieldValue(makeFieldValue(e.target.value)); };
  let _props = {...props};
  delete _props.isRelationship;
  delete _props.charLimit;
  if(_props.invalid) _props.invalid = true;
  return (
    <Select {..._props}  id={field} defaultValue={"placeholder-item"} onChange={onChange}>
      {props.children}
    </Select>
  );

}

export const SelectField = (props) => {
  return (
    <Field field={props.field}>
      <SelectComponent {...props} isRelationship={false} />
    </Field>
  )
};

export const SelectRelationship = (props) => {
  return (
    <Relationship field={props.field}>
      <SelectComponent {...props} isRelationship={true} />
    </Relationship>
  )
};

function CheckboxComponent({value, ...props}){
  // const context = props.isRelationship ? RelationshipContext : FieldContext;
  const [fieldValueObj, setFieldValue] = useContext(FieldContext);
  const fieldValue = fieldValueObj && fieldValueObj.value ? fieldValueObj.value : fieldValueObj;
  const setCheckedValue = (checked) => {
    const checkedSet = new Set(Array.isArray(fieldValue) ? fieldValue : []);
    if(checked){
      checkedSet.add(value);
    }
    else{
      checkedSet.delete(value);
    }
    setFieldValue(Array.from(checkedSet))
    // setFieldValue(['Resume'])
  };

  const onChange = typeof props.onChange !== 'undefined' ? (checked) => {
    setCheckedValue(checked);
    props.onChange(checked);
  } : (checked) => {
    setCheckedValue(checked);
  };

  let _props = {...props};
  delete _props.isRelationship;

  return (
    <Checkbox {..._props} onChange={onChange} checked={Array.isArray(fieldValue) && fieldValue.includes(value)}>
      {props.children}
    </Checkbox>
  );

}

export const CheckboxField = ({field, ...props}) => {
  if(!field){
    return (
      <CheckboxComponent {...props} isRelationship={false} />
    );
  }

  return (
    <Field field={field}>
      <CheckboxComponent {...props} isRelationship={false} />
    </Field>
  )
};
export function CurrentUserEntity({children, ...props}){
  const [userState, ] = useContext(UserContext);
  return (
    <>
    {
      userState.currentUserId ? (
        <Entity {...props} source={{id: userState.currentUserId }}>
          {children}
        </Entity>
      ) : (
        <Loading/>
      )
    }
    </>
  );

}
function MultiSelectComponent({field, isRelationship, relType, size = 'default', ...props}){
  const context = isRelationship ? RelationshipContext : FieldContext;
	const [fieldValueObj, setFieldValue] = useContext(context);
  const fieldValue = fieldValueObj.value ? fieldValueObj.value : fieldValueObj;
  const mapSelectedToFieldValue = (v) => {
    let fieldVal = v.id;
    if(isRelationship){
      fieldVal = { id: v.id };
      if(relType){
        fieldVal.type = relType
      }
    }
    return fieldVal;
  };

  const onChange = (e) => {
    if(typeof props.onChange !== 'undefined'){
      props.onChange(e);
    }
    let mappedVal = e.selectedItems.map(mapSelectedToFieldValue);
    if(mappedVal.length > 0){
      setFieldValue(mappedVal);
    }
  }

  let _props = {...props};
  delete _props.isRelationship;
  delete _props.charLimit;
  if(_props.invalid) _props.invalid = true;
  if(props.isNew){
    return (
      <MultiSelect {...props} onChange={onChange} id={field}  >
        {props.children}
      </MultiSelect>
    );
  }
  const value = () => {
    const ret = fieldValue.relationships[field];
    const ids_arr = ret && ret.data ? ret.data.map((itm) => itm.id ) : [];
    const val = ids_arr.length ? props.items.filter((itm) => ids_arr.includes(itm.id)) : []
    return val
  }
  let sel_values = value();
  if(!sel_values.length) return <></>;
  return (
    <MultiSelect {...props} className={`size-${size}`} id={field} initialSelectedItems={sel_values} onChange={onChange}>
      {props.children}
    </MultiSelect>
  );
}

// export const MultiSelectField = ({field, ...props}) => {
//   return (
//     <Field field={field}>
//       <MultiSelectComponent {...props} isRelationship={false} />
//     </Field>
//   )
// };

export const MultiSelectRelationship = (props) => {
  return (
    <Relationship field={props.field}>
      <MultiSelectComponent {...props} isRelationship={true} />
    </Relationship>
  )
};



const DisplayIfEqualsComponent = ({children, isRelationship, field, terms, like, strict}) => {
  // const fieldContext = useContext(FieldContext);
  const entContext = useContext(EntityContext)
  if(!isRelationship || !entContext.data.relationships || !entContext.data.relationships[field] || !entContext.data.relationships[field].data){
    return (
      <></>
    );
  }
  let match = terms.find((term) => {
    let likes = Array.isArray(like) ? like : [like]
    let like_match = likes.find((lk) => strict ? term.name.toLowerCase() === lk.toLowerCase() : term.name.toLowerCase().includes(lk.toLowerCase()))
    let found =  like_match && entContext.data.relationships[field] && entContext.data.relationships[field].data.id === term.id
    return found
  })
  if(!match){
    return (
      <></>
    );
  }
  return (
    <>{children}</>
  );
};

export const DisplayIfEqualsField = ({children, ...props}) => {
  return (
    <Field {...props}>
      <DisplayIfEqualsComponent {...props}>
        {children}
      </DisplayIfEqualsComponent>
    </Field>
  );
};


const DisplayIfExistsComponent = ({children, isRelationship, field}) => {
  // const fieldContext = useContext(FieldContext);
  const entContext = useContext(EntityContext)
  
  if(!isRelationship || !entContext.data.relationships || !entContext.data.relationships[field] || !entContext.data.relationships[field].data){
    return (
      <></>
    );
  }else if(isRelationship){
    if(entContext.data && entContext.data.relationships && entContext.data.relationships[field] && entContext.data.relationships[field].data){
      if(typeof entContext.data.relationships[field].data.id === 'string' && entContext.data.relationships[field].data.id.length === 0){
        return (
          <></>
        );
      }
    }
  }
  if(entContext.data.relationships[field].data){
    return (
      <>{children}</>
    );
  }
};

export const DisplayIfExistsField = ({children, ...props}) => {
  return (
    <Field {...props}>
      <DisplayIfExistsComponent {...props}>
        {children}
      </DisplayIfExistsComponent>
    </Field>
  );
}


export const DaysAgoFieldValue = (props) => {
  function DaysAgoComponent(){
    const [fieldObj] = useContext(FieldContext);
    const fieldVal = fieldObj && fieldObj.value ? fieldObj.value : fieldObj;
    let daysAgoString = fieldVal ? getPostedLabel(fieldVal) : null;
    return (
      <>
        {daysAgoString}
      </>
    );
  }
  return (
    <Field field={props.field}>
      <DaysAgoComponent/>
    </Field>
  );

};

export const FieldValue = ({field}) => {
  const FieldValueComponent = () => {
    const [value] = useContext(FieldContext);
    const fieldVal = value && value.value ? value.value : value
    let processedVal = null;
    if(typeof fieldVal === `string`){
      processedVal = fieldVal.split('\n').map((text, i) => {
        const lineBreak = i !== 0 ? <br/> : <></>;
        return (
          <span key={i}>{lineBreak}{text}</span>
        );
      });
    }else if(isEmptyObject(fieldVal)){
      processedVal = null;
    }else{
      processedVal = fieldVal;
    }
    return (
      <>
        {processedVal}
      </>
    )
  };

  const fieldContext = useContext(FieldContext);
  if(Array.isArray(fieldContext) && fieldContext[0].value){
    return (
      <FieldValueComponent/>
    );
  }
  return (
    <Field field={field}>
      <FieldValueComponent/>
    </Field>
  )
};

const HTMLTextAreaComponent = () =>{
  const [{value: fieldVal}] = useContext(FieldContext);
  return (
    <div dangerouslySetInnerHTML={{__html: fieldVal}}/>
  );
};

export const HTMLTextArea = ({field}) => {
  const fieldContext = useContext(FieldContext);
  if(Array.isArray(fieldContext) && fieldContext[0].value){
    return (
      <HTMLTextAreaComponent/>
    );
  }
  return (
    <Field field={field}>
      <HTMLTextAreaComponent/>
    </Field>
  );
};

const ExcerptComponent = ({title}) =>{
  let context = useContext(FieldContext);
  let fieldVal = context[0] ? context[0].value : null;
  return fieldVal ? <><div className="excerpt"><strong>{title}:</strong>{' '}{trimSummary(removeTags(fieldVal), 250)}</div></> : <></>;
};


export const HTMLExcerpt = ({field, title}) => {
  const fieldContext = useContext(FieldContext);
  if(Array.isArray(fieldContext) && fieldContext[0].value){
    return (
      <ExcerptComponent title={title} />
    );
  }
  return (
    <Field field={field}>
      <ExcerptComponent title={title} />
    </Field>
  );
};



export function SubFieldValue({subField}){
  const [val] = useContext(FieldContext);
  const subVal = val && val[subField] ? val[subField] : null;
  return (
    <>{subVal}</>
  );
}

export const SubmitButton = SubmitButtonComponent((props) => {
  return (
    <Button {...props} onClick={props.onClick}>{props.children}</Button>
  );
});


const ModalComponent = SubmitButtonComponent(({onClick, ...props}) => {
  const { cancel } = useContext(EntityContext);

  const onRequestClose = () => {
    if(props.onRequestClose){
      props.onRequestClose();
    }
    cancel();
  };

  const onRequestSubmit = (e) => {
    if(props.onRequestSubmit){
      props.onRequestSubmit(e);
    }
    onClick(e);
  };
  return (
    <Modal key="modal" {...props} onRequestSubmit={onRequestSubmit} onRequestClose={onRequestClose}>
      {props.children}
    </Modal>
  );
});

export function ModalEntity({source, type, ...modalProps}){
  return (
    <Entity key="entity" source={source} type={type} componentId="entity" >
      <ModalComponent key="modal-component" {...modalProps}/>
    </Entity>
  );
}

function TextAreaComponent({labelText, placeholder, charLimit, onChange, invalid}) {
  const [val, setVal] = useContext(FieldContext);
  const [limitText, setLimitText] = useState('');

  const [exceededMax, setExceededMax] = useState(false)
  const quillRef = useRef(null)

  useEffect(() => {
    let len = val && val.value ? removeTags(val.value).length : 0;
    let text = `${len}/${charLimit}`
    setLimitText(text)
    setExceededMax(len > charLimit)
  }, [val])

  const handleChange = (value) => {
    onChange();
    setVal({ value, format: 'basic_html' })
  }
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [ {'list': 'bullet'}],
      ['link', 'clean']
    ],
  };
  const formats = null;

  return (
    <fieldset className={`bx--fieldset ${invalid || exceededMax ? 'is-invalid' : ''}`}>
      <legend className="bx--label"></legend>
      <div className="text-area-labels">
        <label className="bx--label">{labelText}</label>
        <label className="bx--label">{limitText}</label>
      </div>
      <div className="bx--wysiwyg">
        {typeof document !== 'undefined' && 
          <ReactQuill
            value={val ? val.value : null}
            onChange={handleChange}
            theme="snow"
            ref={quillRef}
            modules={modules}
            formats={formats}
            placeholder={placeholder}
          />
        }
      </div>
    </fieldset>
  )

}


export function TextAreaField({field, labelText, placeholder, charLimit, onChange, invalid}){
  return (
    <Field field={field}>
      <TextAreaComponent labelText={labelText} placeholder={placeholder} charLimit={charLimit} onChange={onChange} invalid={invalid} />
    </Field>
  );
}